import { getAxiosClientWithToken } from './index';
import {getAxiosClientWithJWTToken} from 'api';
import { ListStatisticHistoryRequest } from 'redux/slice/ChargerHistorySlice';
import {chargerHistoryDataChartType} from '../types/index';

export const getDetailChargerHistory = (params: { id: number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/charge-history/details?uuid=`+`${params.id}`);
};

export const getPrefectureChargerHistory = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/prefecture`);
};
export const getListCityByPrefecture = (params: { prefecture_code: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/prefecture/city/`, { params });
};

export const deleteSection = (id: string | number) => {
    return getAxiosClientWithToken().delete(`admin/charging-history/${id}`);
};

export const getChartData = (params:any) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/data-chart`, { params });
};

export const getDashboardSummary = (params:any) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/dashboard/summary`, { params });
};

export const getDetailBookingCancel = (params: { id: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/booking/${params.id}`);
};

export const getStatisticHistory = (params: any) => {
    return getAxiosClientWithJWTToken().get('charger/statistics/charger-history', { params });
};


