import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { createInvoiceRequest, getFacility } from 'api/invoice';
import { Constant } from 'config/constant';
import { type } from 'os';
import { RootState } from 'redux/store';
import { invoiceData } from 'types';

export type Facility = {
    uuid: string;
    name: string;
};

export type InvoiceData = {
    status: number;
    facilityData: {
        error: boolean;
        loading: boolean;
        success: boolean;
        data: Facility[];
    };
    invoice: {
        error: boolean;
        loading: boolean;
        success: boolean;
        data: {
           file_url: string
        };
    }
}

export const InvoiceSlice = createSlice({
    name: 'invoice',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        facilityData: {
            error: false,
            loading: false,
            success: false,
            data: [],
        },
        invoice: {
            error: false,
            loading: false,
            success: false,
            data: {
                file_url: ''
            },
        }
    } as unknown as InvoiceData,
    reducers: {
        setFacility: (state: InvoiceData, { payload }) => {
            state.facilityData.data = payload;
        },
        setInvoice: (state: InvoiceData, action) => {
            state.invoice.data = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        resetInvoice: (state: InvoiceData) => {
            state.invoice = {
                error: false,
                loading: false,
                success: false,
                data: {
                    file_url: ''
                },
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacilityData.pending, (state: InvoiceData) => {
                state.facilityData.loading = true;
            })
            .addCase(fetchFacilityData.rejected, (state: InvoiceData, { payload }) => {
                state.facilityData.loading = false;
                state.facilityData.success = false;
                state.facilityData.error = true;
            })
            .addCase(fetchFacilityData.fulfilled, (state: InvoiceData, action) => {
                state.facilityData.loading = false;
                state.facilityData.success = true;
                state.facilityData.error = false;
            })
            .addCase(createInvoice.pending, (state: InvoiceData) => {
                state.invoice.loading = true;
            })
            .addCase(createInvoice.rejected, (state: InvoiceData, { payload }) => {
                state.invoice.loading = false;
                state.invoice.success = false;
                state.invoice.error = true;
            })
            .addCase(createInvoice.fulfilled, (state: InvoiceData, action) => {
                state.invoice.loading = false;
                state.invoice.success = true;
                state.invoice.error = false;
            })
    }
})


export const fetchFacilityData = createAsyncThunk('facility/list-uuid-name', async (data, { dispatch }) => {
    try {
        const response = await getFacility();
        const { data = [], success } = response.data;
        if (success) {
            dispatch(setFacility(data));
            return true;
        }
        return false;
    } catch (error: any) {}
    return false;
});

export const createInvoice = createAsyncThunk('facility/invoice', async (params: invoiceData, { dispatch, rejectWithValue }) => {
    try {
        let response = await createInvoiceRequest(params);
        const { body = {}, code } = response.data;

        if (body) {
            dispatch(setInvoice(body));
            return true;
        }
    } catch (error: any) {
        dispatch(setInvoice(error));
        return rejectWithValue(getErrorMessage(error));
    }
});

export const {
    setFacility,
    setInvoice,
    resetInvoice,
} = InvoiceSlice.actions;

export const invoiceSelector = (state: RootState) => state.invoice