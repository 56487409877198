import {getAxiosClientWithJWTToken} from 'api';
import {RegisterChargerMakerData, chargerMakerData} from 'types'

export const createChargerMaker= (params: RegisterChargerMakerData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_makers/create`, params);
};

export const getListChargerMaker = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger_makers', { params });
};

export const UpdateChargerMaker = (params: { uuid: string, name: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_makers/update`, params);
};
