import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ADMIN, SIDEBAR_WIDTH, SUPER_ADMIN, VIEWER } from 'config/constant';
import { Image, Layout, Menu, SiderProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValidRoles, useIsActiveMenu, useIsMobile, useSelectedMenu } from 'lib/utils';
import UserIcon from 'assets/images/ic-user.svg';
import type { MenuProps } from 'antd';
import SettingIcon from 'assets/images/setting-icon.svg';
import PlaceIcon from 'assets/images/place-icon.svg';
import ChargeHistory from 'assets/images/ic-charger-history.svg';
import HomeIcon from 'assets/images/sidebar/home.svg';
import MapIcon from 'assets/images/sidebar/map.svg';
import { useAppSelector } from 'redux/hooks';
import { selectAuth } from 'redux/slice/authSlice';
import NotificationIcon from 'assets/images/ic-notification.svg';
import InvoiceIcon from 'assets/images/ic-invoice.png';
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

type Props = SiderProps & {
    onCloseCollapsed?: () => void;
    setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const Sidebar = (props: Props) => {
    const { breakpoint, collapsedWidth, onBreakpoint, collapsed, onCollapse, onCloseCollapsed, setIsCollapsed } = props;
    const { pathname } = useLocation();
    const [openKeys, setOpenKeys] = useIsActiveMenu();
    const selectedMenus = useSelectedMenu();
    const navigate = useNavigate();
    const { role } = useAppSelector(selectAuth);
    const isMobile = useIsMobile();

    const onOpenChange = (keys: string[]) => {
        let newOpenKeys = [];
        let lastKey = keys.pop();
        if (lastKey) {
            if (lastKey === 'setting-notifications') {
                newOpenKeys.push(...keys);
            }
            newOpenKeys.push(lastKey);
        }
        setOpenKeys(newOpenKeys);
    };

    const { t } = useTranslation(['home']);

    const menuItems: MenuProps['items'] = [
        {
            key: 'home',
            icon: <Image src={HomeIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : t('side_bar_3.1')}`,
        },
        {
            key: 'map',
            icon: <Image src={MapIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : t('side_bar_3.2')}`,
        },
        {
            key: 'places',
            icon: <Image src={PlaceIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : t('side_bar_3.3')}`,
        },
        {
            key: 'users',
            icon: <Image src={UserIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : t('side_bar_3.4')}`,
        },
        {
            key: 'chargers-history',
            icon: <Image src={ChargeHistory} width={20} preview={false} />,
            className: 'my-0  site-menu-item',
            label: `${collapsed ? '' : t('side_bar_3.5')}`,
            theme: 'light',
        },
        isValidRoles(role, [SUPER_ADMIN, ADMIN])
            ? {
                  key: 'invoice',
                  icon: <Image src={InvoiceIcon} width={20} preview={false} />,
                  className: 'my-0 site-menu-item',
                  label: `${collapsed ? '' : t('side_bar_3.20')}`,
                  theme: 'light',
              }
            : null,
        {
            key: 'settings',
            icon: <Image src={SettingIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : t('side_bar_3.6')}`,
            theme: 'light',
            children: [
                isValidRoles(role, [SUPER_ADMIN, ADMIN])
                    ? {
                          key: 'accounts',
                          className: 'my-0 site-menu-item',
                          label: t('side_bar_3.11'),
                      }
                    : null,
                isValidRoles(role, [SUPER_ADMIN])
                    ? {
                        key: 'facility-type',
                        className: 'my-0 site-menu-item',
                        label: t('side_bar_3.15'),
                    }
                    : null,
                isValidRoles(role, [SUPER_ADMIN])
                ? {
                    key: 'charger-maker',
                    className: 'my-0 site-menu-item',
                    label: t('side_bar_3.16'),
                }
                : null,
                isValidRoles(role, [SUPER_ADMIN])
                ? {
                    key: 'charger-type',
                    className: 'my-0 site-menu-item',
                    label: t('side_bar_3.17'),
                }
                : null,
                isValidRoles(role, [SUPER_ADMIN])
                ? {
                    key: 'charger-protocol',
                    className: 'my-0 site-menu-item',
                    label: t('side_bar_3.18'),
                }
                : null,

            ],
        },
        isValidRoles(role, [SUPER_ADMIN, ADMIN, VIEWER])
            ? {
                  key: 'setting-notifications',
                  icon: <Image src={NotificationIcon} width={20} preview={false} />,
                  className: 'my-0 site-menu-item',
                  label: `${collapsed ? '' : t('side_bar_3.8')}`,
                  theme: 'light',
                  children: [
                      isValidRoles(role, [SUPER_ADMIN, ADMIN, VIEWER])
                          ? {
                                key: 'notification',
                                className: 'my-0 site-menu-item',
                                label: t('side_bar_3.9'),
                            }
                          : null,
                      isValidRoles(role, [SUPER_ADMIN])
                          ? {
                                key: 'category',
                                className: 'my-0 site-menu-item',
                                label: t('side_bar_3.10'),
                            }
                          : null,
                  ],
              }
            : null,
    ];

    const onMenuClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'home':
                navigate('');
                break;
            case 'map':
                navigate('/map');
                break;
            case 'chargers':
                navigate('/chargers');
                break;
            case 'users':
                navigate('/users');
                break;
            case 'chargers-management':
                navigate('/places/chargers-management');
                break;
            case 'chargers-history':
                navigate('/chargers-history');
                break;
            case 'change-password':
                navigate('/change-password');
                break;
            case 'facility-type':
                navigate('facility-type');
                break;
            case 'charger-maker':
                navigate('charger-maker');
                break;   
            case 'charger-type':
                navigate('charger-type');
                break;
            case 'charger-protocol':
                navigate('charger-protocol');
                break;
            case 'places':
                navigate('/places');
                break;
            case 'accounts':
                navigate('/accounts');
                break;
            case 'notification':
                navigate('/notification');
                break;
            case 'category':
                navigate('/category');
                break;
            case 'invoice':
                navigate('/invoice');
                break;    
            default:
                break;
        }
    };
    const redirectHome = () => {
        navigate('/');
    };
    useEffect(() => {
        onCloseCollapsed?.();
    }, [onCloseCollapsed, pathname]);

    return (
        <Sider
            breakpoint={breakpoint}
            collapsedWidth={collapsedWidth}
            onBreakpoint={onBreakpoint}
            onCollapse={onCollapse}
            collapsed={collapsed}
            width={SIDEBAR_WIDTH}
            className='sidebar-collapsed-toggle position-fixed top-0 bottom-0 start-0 site-sidebar ant-menu-light'
            style={{ transition: '0.4s' }}
            onMouseEnter={() => setIsCollapsed(false)}
            onMouseLeave={() => setIsCollapsed(true)}
        >
            {/*<Header className='site-sidebar-header text-nowrap px-4 text-center'>*/}
            {/*    <Image src={Logo} preview={false} onClick={redirectHome} />*/}
            {/*</Header>*/}
            {/* {!isMobile && <Image src={Hamburger} className='menuSidebar' onClick={() => setIsCollapsed(!collapsed)} preview={false} />} */}
            <Menu
                key={pathname}
                defaultSelectedKeys={selectedMenus}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                theme='dark'
                mode='inline'
                className='position-relative overflow-auto ant-sidebar-nav'
                items={menuItems}
                onClick={onMenuClick}
            />
        </Sider>
    );
};

export default Sidebar;
