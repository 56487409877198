import { getAxiosClientWithToken, getAxiosClientWithJWTToken } from 'api';
import { CategoryData } from 'redux/slice/categorySlice';
import { handleFormData } from 'lib/utils';

export const getCategoryList = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/category');
};

export const getCategory = (params: { uuid?: number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/category/getbyuuid?uuid=${params.uuid}`);
};

export const updateCategory = (params: CategoryData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/category`, params);
};
