import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Constant} from 'config/constant';
import {RootState} from 'redux/store';
import {getErrorMessage,} from 'api';
import {
    RegisterChargerTypeData
} from 'types';
import {createChargerType, getListChargerType, UpdateChargerType} from 'api/chargerType';
import { Data } from '@react-google-maps/api';

export type chargerTypeData = {
    uuid: string,
    name: string
}
export type ListChargerType = {
    error: boolean;
    loading: boolean;
    success: boolean;
    chargerType: chargerTypeData[];
    status: string;
};

export type ChargerTypeState = {
    status: number;
    chargerTypeData: chargerTypeData[];
    listChargerType: ListChargerType;
    detailsChargerType: chargerTypeData;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | RegisterChargerTypeData;
    };
    update: {
        success: boolean;
        error: boolean;
        messages: string | RegisterChargerTypeData;
        loading: boolean;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    detail: {
        success: boolean;
        error: boolean;
        messages: string | RegisterChargerTypeData;
        loading: boolean;
        dataDetail: chargerTypeData;
    };
}

export const createChargerTypeA = createAsyncThunk(
    'admin/charger-type/store',
    async (params: RegisterChargerTypeData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createChargerType(params);
            const { data = {}, success } = response.data;
            if (success) {

                dispatch(setNewChargerType(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setNewChargerType(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateChargerType = createAsyncThunk(
    '/admin/charger-type/update',
    async (params: { uuid: string, name: string ,icon_url:string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await UpdateChargerType(params);
            const { data = {}, success } = response.data;
            if (success) {
            dispatch(setUpdateChargerType({params}));
                return true;
            }
        } catch (error: any) {
            dispatch(setUpdateChargerType(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const chargerTypeSlice = createSlice(
    {
        name: 'chargerType',
        initialState: {
            status: Constant.DEFAULT_STATUS,
            chargerTypeData: [],
            register: {
                loading: false,
                success: false,
                error: false,
                message: '',
            },
            update: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            delete: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            listChargerType: {
                error: false,
                loading: false,
                success: false,
                notification: [],
                pagination: {
                    total: 0,
                    perPage: Constant.PAGE_SIZE_NEW,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                },
                status: '',
            },
            listSearchPlace: {
                error: false,
                loading: false,
                success: false,
                places: [],
            }
        } as unknown as ChargerTypeState,
        reducers:{
            resetRegisterState: (state: ChargerTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.register = {
                    success: false,
                    loading: false,
                    message: '',
                    error: false,
                };
            },
            setNewChargerType: (state, action) => {
                state.chargerTypeData = action?.payload ?? [];
                state.status = action?.payload?.response?.status;
            },
            setListChargerType: (state: ChargerTypeState, { payload }) => {
                const { chargerType, pagination } = payload;
                state.listChargerType.chargerType = chargerType;
            },
            setDetailsChargerType:(state: ChargerTypeState, {payload}) => {
                const {detailsChargerType} = payload;
                state.detailsChargerType = {
                    name: payload.detailsChargerType.name,
                    uuid: payload.detailsChargerType.uuid
                }
            },
            setDeleteChargerType: (state: ChargerTypeState, { payload }) => {
                const { chargerType } = payload;
                state.listChargerType.chargerType = chargerType;
                state.listChargerType.chargerType = payload?.response?.status;
            },
            setUpdateChargerType: (state, action) => {
                state.chargerTypeData = action?.payload?.params ?? [];
                state.status = action?.payload?.response?.status;
            },
            resetUpdateState: (state: ChargerTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetDeleteState: (state: ChargerTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.delete = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetListChargerTypeState: (state: ChargerTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(createChargerTypeA.pending, (state: ChargerTypeState) => {
                    state.register.loading = true;
                })
                .addCase(createChargerTypeA.rejected, (state: ChargerTypeState, { payload }) => {
                    state.register.loading = false;
                    state.register.success = false;
                    state.register.error = true;
                    state.register.message = payload as string | RegisterChargerTypeData;
                })
                .addCase(createChargerTypeA.fulfilled, (state: ChargerTypeState) => {
                    state.register.loading = false;
                    state.register.success = true;
                    state.register.error = false;
                })
                .addCase(updateChargerType.pending, (state: ChargerTypeState) => {
                    state.update.loading = true;
                })
                .addCase(updateChargerType. rejected, (state: ChargerTypeState) => {
                    state.update.loading = false;
                    state.update.success = false;
                    state.update.error = true;
                })
                .addCase(updateChargerType. fulfilled, (state: ChargerTypeState) => {
                    state.update.loading = false;
                    state.update.success = true;
                    state.update.error = true;
                })
        },
    }
);

export const fetchListChargerType = createAsyncThunk(
    'admin/charger-type',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListChargerType(params);
            const {
                data = [],
                success,
            } = response.data;
            if (success) {
                let m = 0;
                if(data.length>0) {
                    for (const elem of data) {
                        m+=1;
                        elem['no'] = m;
                    }
                }
                dispatch(setListChargerType({ chargerType: data }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListChargerType(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const {
    setListChargerType,
    resetRegisterState,
    setNewChargerType,
    setDeleteChargerType,
    resetUpdateState,
    setUpdateChargerType,
    resetDeleteState,
    resetListChargerTypeState,
    setDetailsChargerType
} = chargerTypeSlice.actions
export const chargerTypeSelector = (state: RootState) => state.chargerType;
