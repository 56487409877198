import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import {
    createCharger,
    detailCharger,
    editCharger,
    exportCSV,
    getListCharger,
    getSearchPlaceInListCharger,
    listByPlaceInCharger,
    getChargerOperationStatus,
    getChargerType,
    getChargerStatus,
    getChargerMaker,
    getListBooking,
    cancelBookingReservationList,
    updateMasterSlaveFlagCharger,
    getListErrorsStatus,
    getListErrorsLog,
    updateStatusCharger,
    getChargerModel,
    getItemPlaceStatistic,
    fetchChargingHistory,
    getChargerMakerType,
    fetchChargingHistoryByCharger,
    getStatisticHistoryByCharger,
    exportChargerHistoryCSV,
    stopCharger,
    openCharger,
    getStatusNitto,
    connectNitto,
    getListStatusNitto,
    getListSupplyStatus,
    startNitto,
    stopNitto,
    getInfoNitto,
    connectKazam,
    getStatusKazamCharger,
    getStatusKazamCharging,
    getListStatusKazamCharger,
    getListStatusKazamCharging,
    getListKazamSupplyStatus,
    startKazam,
    stopKazam,
    getInfoKazam,
} from 'api/charger';
import { Constant } from 'config/constant';
import { getErrorMessage } from 'api';
import { CreateChargerData, CSVexport, ListBookingCharger, RegisterChargerValidate, UpdateStatusCharger, ChargerBooking, TurnOnOff } from 'types';
import { downloadCsv } from 'lib/utils';
import { fetchPlaceStatistic } from './placeSlice';
import moment from 'moment';

export type PlaceData = {
    id: number;
    name: string;
    address: string;
    code: string;
    uuid: string;
};

export type ListPlaceData = {
    uuid: number;
    name: string;
    address: string;
    image_url: [];
};

export type ListChargerRequest = {
    page?: number;
    facility_uuid?: string;
    keyword?: string;
    charger_operation_status?: number | string;
    charger_use?: number | string;
    charger_type_id?: number | string;
    charger_maker_id?: number | string;
    charger_model_id?: number | string;
    code?: string;
    device_code?: number | string;
    version_name?: string;
    capacity?: number | string;
    charging_tab?: number;
};

export type ListCharger = {
    error: boolean;
    loading: boolean;
    success: boolean;
    chargers: ChargerData[];
    pagination: Pagination;
};

export type StatusNittoData = {
    id: number;
    serialNum: string;
    statusDateTime: string;
    lineStatus: string;
    chgStatus: string;
    errStatus: string;
    errCode: string;
};

export type StatusKazamChargerData = {
    id: number;
    charger_id: number;
    device_code: string;
    charge_state: string;
    c_type: string;
    cp_state: string;
    mt_val: string;
    imsi: string;
    op: string;
    rssi: string;
    vol: string;
    timestamp: string;
};

export type StatusKazamChargingData = {
    id: number;
    charger_id: number;
    device_code: string;
    actpower: string;
    duration: string;
    start_time: string;
    end_time: string;
    end_reason: string;
    tx_status: string;
    total_usage: string;
};

export type SupplyStatusData = {
    id: number;
    serialNum: string;
    chgDateTimeStart: string;
    chgDateTimeEnd: string;
    chgTime: string;
    chgPow: string;
    currentPeak: string;
    stopReason: string;
};

export type KazamSupplyStatusData = {
    charger_id: number;
    device_code: string;
    actpower: string;
    duration: string;
    start_time: string;
    end_time: string;
    end_reason: string;
    tx_status: string;
    total_usage: string;
};

export type ListStatusNitto = {
    error: boolean;
    loading: boolean;
    success: boolean;
    nittoData: StatusNittoData[];
    pagination: Pagination;
};

export type ListStatusKazamCharger = {
    error: boolean;
    loading: boolean;
    success: boolean;
    kazamChargerData: StatusKazamChargerData[];
    pagination: Pagination;
};

export type ListStatusKazamCharging = {
    error: boolean;
    loading: boolean;
    success: boolean;
    kazamData: StatusKazamChargingData[];
    pagination: Pagination;
};

export type ListSupplyStatus = {
    error: boolean;
    loading: boolean;
    success: boolean;
    supplyStatusData: SupplyStatusData[];
    pagination: Pagination;
};

export type ListKazamSupplyStatus = {
    error: boolean;
    loading: boolean;
    success: boolean;
    supplyKazamStatusData: KazamSupplyStatusData[];
    pagination: Pagination;
};

export type ChargerData = {
    facility_uuid: string;
    code: string;
    type_place: Number;
    type_charger: Number;
    place_type: string;
    charger_type: string;
    status: Number;
    master_slave: Number;
    charger_type_icon_url: string;
    master_slave_id: number;
    address: string;
    charger_brand: number;
    charger_brand_id: string;
    charger_operation_status_id: string;
    charger_status: string;
    charger_status_id: number;
    charger_type_id: number;
    charging_amount: number;
    charging_fee: number;
    charging_method: number;
    device_code: string;
    electric_fee: number;
    electric_method: number;
    idling_time: number;
    image_url: string[];
    master_slave_flag: number;
    model: string;
    place_id: string;
    place_name: string;
    place_type_id: number;
    charger_privacy_flag_id: number;
    charger_privacy_flag: string;
    capacity: number;
    charger_maker_id: number;
    charger_maker: string;
    time_last_used: string;
    uuid: string;
};

export type ListPlaceRequest = {
    place_id?: number[];
    page?: number;
};

export type SearchPlaceRequest = {
    facility_uuid?: string;
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

type TypeData = {
    uuid: number;
    name: string;
    type?: number;
};

export type BookingList = {
    charger_id?: number | string;
    charging_time_start?: string;
    charging_time_end?: string;
};

export type ErrorsStatusParams = {
    type?: number | string;
};

export type ErrorsLogParams = {
    day_search: string;
    error_status_id?: number | string;
    id?: number | string;
};

export type ChargerModelParams = {
    charger_maker_id?: number | string;
    charger_type_id?: number | string;
};

export interface ErrorsStatus {
    id: Number;
    name: string;
}

export type CancelBooking = {
    id: number;
    reason: string;
};

export type ChargerModel = {
    id: number;
    name: string;
};

export type UpdateMasterSlave = {
    id?: string | number;
    master_slave_flag?: number;
    is_update: number;
};

export type ErrorLog = {
    id: number;
    date_create: string;
    time_create: string;
    error_status_id: number;
    error_status: string;
    error_log: string;
};

export type Charger = {
    total: number;
    detail: {};
};

export type ChargingStatus = {
    total: number;
    detail: {
        'free': number;
        'busy': number;
        'error': number;
    };
};

export type ChargerPrivacy = {
    detail: {
        'private': number;
        'public': number;
    };
};

export type ChargerTypePlace = {
    detail: {};
};

export type ChargerStatus = {
    detail: {
        'available': number;
        'unavailable': number;
    };
};

export type PlaceItemStatistic = {
    charger_status: ChargerStatus;
    charger_privacy: ChargerPrivacy;
    charging_status: ChargingStatus;
    charger_type: ChargerTypePlace;
    charger_protocol:ChargerTypePlace;
};

export type ChargingHistory = {
    page?: number;
    place_id?: string[] | number[];
    place_code?: string | number;
    start_time?: string;
    end_time?: string;
    charger_status?: string;
    charging_tab?: string;
};

export type NittoChargerStatus = {
    product_cd?: string;
    serial_num?: string;
    max_chg_time?: string;
};

export type KazamChargerStatus = {
    duration?: string;
};

type ListChargingHistory = {
    id: string;
    place_id: string;
    place_code: string;
    place: string;
    user_id: string;
    user_first_name: string;
    user_last_name: string;
    user_name: string;
    charger_id: string;
    charger_code: string;
    charger_type_id: string;
    charger_type: string;
    charger_type_icon_url: string;
    charging_status_id: string;
    charging_status: string;
    charging_status_icon_url: string;
    charging_date_start: string;
    charging_time_start: string;
    charging_bill_status: string;
    charging_date_end: string;
    charging_time_end: string;
    charging_time: string;
    charging_amount: string;
    electricity_fee: string;
    charging_fee: string;
    charging_power: string;
    electric_bill: string;
    charging_bill: string;
};

export type ListStatisticRequestByCharger = {
    start_time?: string;
    end_time?: string;
    charging_tab?: string | number;
};

type NittoStatus = {
    lineStatus: string;
    chgStatus: string;
    errStatus: string;
    errCode: string;
};

type KazamStatusCharger = {
    charge_state_raw: string;
    charge_state: string;
    res_cd: string;
    res_cd_name: string;
};

type KazamStatusCharging = {
    tx_status_raw: string;
    tx_status: string;
    end_reason_raw: string;
    end_reason: string;
    res_cd: string;
    res_cd_name: string;
};

export type SendConnectNitto = {
    product_cd?: string;
    serial_num?: string;
    rc_cntr_cd?: number;
};

export type ConnectNitto = {
    res_cd?: string;
    res_cd_name?: string;
};

export type SendConnectKazam = {
    command?: string;
    duration?: string;
    res?: string;
};

export type ConnectKazam = {
    command?: string;
    duration?: string;
    res?: string;
};

export type ChargerState = {
    status: number;
    listCharger: ListCharger;
    listStatusNitto: ListStatusNitto;
    listSupplyStatus: ListSupplyStatus;
    listStatusKazamCharger: ListStatusKazamCharger;
    listStatusKazamCharging: ListStatusKazamCharging;
    listKazamSupplyStatus: ListKazamSupplyStatus;
    createCharger: CreateChargerData[];
    placeItemStatistic: {
        error: boolean;
        success: boolean;
        loading: boolean;
        message: string;
        data: PlaceItemStatistic;
    };
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        errors: string;
        message: string | RegisterChargerValidate;
    };
    update: {
        success: boolean;
        error: boolean;
        errors: string;
        messages: string | RegisterChargerValidate;
        loading: boolean;
    };
    stopCharger: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    openCharger: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    detailCharger: {
        success: boolean;
        message: string | RegisterChargerValidate;
        data: CreateChargerData;
        loading: boolean;
        error: boolean;
    };
    listSearchPlace: {
        error: boolean;
        loading: boolean;
        success: boolean;
        places: PlaceData[];
    };
    listChargerByPlace: {
        error: boolean;
        loading: boolean;
        success: boolean;
        listPlaces: ListPlaceData[];
        pagination: Pagination;
    };
    downloadCSVData: { error: boolean; loading: boolean; success: boolean; data: string };
    downloadCSVChargerData: { error: boolean; loading: boolean; success: boolean; data: string };
    masterChargerStatus: { error: boolean; loading: boolean; success: boolean; data: TypeData[] };
    masterChargerType: { error: boolean; loading: boolean; success: boolean; data: TypeData[] };
    masterChargerMakerType: { error: boolean; loading: boolean; success: boolean; data: TypeData[] };
    masterChargerOperationStatus: { error: boolean; loading: boolean; success: boolean; data: TypeData[] };
    masterChargerMaker: { error: boolean; loading: boolean; success: boolean; data: TypeData[] };

    listBooking: {
        error: boolean;
        loading: boolean;
        success: boolean;
        message: string;
        data: ListBookingCharger[];
    };
    cancelBooking: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    updateMasterSlave: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    newCharger: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    errorsStatus: {
        loading: boolean;
        success: boolean;
        error: boolean;
        data: ErrorsStatus[];
    };
    errorsLogs: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
        data: ErrorLog[];
        pagination: Pagination;
    };
    updateStatus: {
        success: boolean;
        error: boolean;
        message: string;
        loading: boolean;
    };
    chargerModel: {
        success: boolean;
        error: boolean;
        loading: boolean;
        data: ChargerModel[];
    };
    listChargingHistory: {
        success: boolean;
        error: boolean;
        loading: boolean;
        chargerHistory: ListChargingHistory[];
        pagination: Pagination;
        status: number;
    };
    listHistory: {
        success: boolean;
        error: boolean;
        loading: boolean;
        chargerHistory: ListChargingHistory[];
        pagination: Pagination;
        status: number;
    };
    chargerBooking: {
        success: boolean;
        message: string;
        data: ChargerBooking;
        loading: boolean;
        error: boolean;
    };
    statusNitto: {
        success: boolean;
        message: string;
        data: NittoStatus;
        loading: boolean;
        error: boolean;
    };
    connectNitto: {
        success: boolean;
        message: string;
        data: ConnectNitto;
        loading: boolean;
        error: boolean;
    };
    startNitto: {
        success: boolean;
        message: string;
        data: ConnectNitto;
        loading: boolean;
        error: boolean;
    };
    stopNitto: {
        success: boolean;
        message: string;
        data: ConnectNitto;
        loading: boolean;
        error: boolean;
    };
    infoNitto: {
        success: boolean;
        message: string;
        data: SendConnectNitto;
        loading: boolean;
        error: boolean;
    };
    statusKazamCharger: {
        success: boolean;
        message: string;
        data: KazamStatusCharger;
        loading: boolean;
        error: boolean;
    };
    statusKazamCharging: {
        success: boolean;
        message: string;
        data: KazamStatusCharging;
        loading: boolean;
        error: boolean;
    };
    connectKazam: {
        success: boolean;
        message: string;
        data: ConnectKazam;
        loading: boolean;
        error: boolean;
    };
    startKazam: {
        success: boolean;
        message: string;
        data: ConnectKazam;
        loading: boolean;
        error: boolean;
    };
    stopKazam: {
        success: boolean;
        message: string;
        data: ConnectKazam;
        loading: boolean;
        error: boolean;
    };
    infoKazam: {
        success: boolean;
        message: string;
        data: SendConnectKazam;
        loading: boolean;
        error: boolean;
    };
};

export const fetchListCharger = createAsyncThunk('admin/charger', async (params: ListChargerRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getListCharger(params);
        const {
            data = [],
            total = 0,
            perPage = Constant.PAGE_SIZE,
            currentPage = Constant.DEFAULT_PAGE,
            lastPage = Constant.DEFAULT_PAGE,
            success,
        } = response.data;
        if (success) {
            dispatch(setListCharger({ chargers: data, pagination: { total, perPage, currentPage, lastPage } }));
            return true;
        }
    } catch (error: any) {
        dispatch(setListCharger(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return rejectWithValue(false);
});

export const createMyCharger = createAsyncThunk('admin/charger/store', async (params: CreateChargerData, { dispatch, rejectWithValue }) => {
    try {
        let response = await createCharger(params);
        const { data = {}, success } = response.data;
        if (success) {
            dispatch(setNewCharger(data));
            return true;
        }
        return rejectWithValue(' 施設の登録に失敗しました。');
    } catch (error: any) {
        dispatch(setNewCharger(error));
        return rejectWithValue(getErrorMessage(error));
    }
});

export const editMyCharger = createAsyncThunk(
    '/admin/charger/update/:id',
    async (params: { data: CreateChargerData; id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await editCharger(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setEditCharger(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setEditCharger(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getDetailChargerData = createAsyncThunk(
    'admin/charger/:id',
    async (params: { id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await detailCharger(params);
            const { success } = response?.data;
            if (success) {
                dispatch(setDetailCharger(response?.data));
                return success;
            }
        } catch (error: any) {
            dispatch(setDetailCharger(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListSearchPlace = createAsyncThunk('place/search', async (params: SearchPlaceRequest, { dispatch }) => {
    try {
        const response = await getSearchPlaceInListCharger(params);
        const { data = [], success } = response.data;

        if (success) {
            dispatch(setListSearchPlaceCharger({ places: data }));
            return true;
        }
    } catch (error: any) {
        dispatch(setListSearchPlaceCharger(error));
    }
    return false;
});

export const fetchChargerStatus = createAsyncThunk('admin/charger-status', async (params: { type?: number }, { dispatch }) => {
    try {
        const response = await getChargerStatus(params);
        const { data = [], success } = response.data;
        if (success) {
            dispatch(setChargerStatus(data));
            return true;
        }
    } catch (error: any) {}
    return false;
});

export const fetchChargerType = createAsyncThunk('admin/charger-type', async (_, { dispatch }) => {
    try {
        const response = await getChargerType();
        const { data = [], success } = response.data;

        if (success) {
            dispatch(setChargerType(data));
            return true;
        }
    } catch (error: any) {}
    return false;
});

export const fetchChargerMakerType = createAsyncThunk(
    'charger_types',
    async (_, { dispatch }) => {
        try {
            const response = await getChargerMakerType();
            const { data = [], success } = response?.data;
            if (success) {
                dispatch(setChargerMarkerType(data));
                return true;
            }
        } catch (error: any) {}
        return false;
    }
);

export const fetchChargerOperationStatus = createAsyncThunk('admin/charger-operation-status', async (data, { dispatch }) => {
    try {
        const response = await getChargerOperationStatus();
        const { data = [], success } = response.data;

        if (success) {
            dispatch(setChargerOperationStatus(data));
            return true;
        }
    } catch (error: any) {}
    return false;
});

export const fetchChargerMaker = createAsyncThunk('admin/charger-maker', async (data, { dispatch }) => {
    try {
        const response = await getChargerMaker();
        const { data = [], success } = response.data;

        if (success) {
            dispatch(setChargerMaker(data));
            return true;
        }
    } catch (error: any) {}
    return false;
});

export const fetchListChargerByPlace = createAsyncThunk(
    'admin/place/list-place-selected',
    async (params: ListPlaceRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await listByPlaceInCharger(params);
            const {
                data: chargers = [],
                total = 0,
                perPage = Constant.PAGE_SIZE,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;

            if (success) {
                dispatch(setListChargerByPlace({ chargers, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListChargerByPlace(error));
        }
        return rejectWithValue(false);
    }
);

export const exportChargerCSV = createAsyncThunk(
    '/admin/charging-history/export',
    async (params: CSVexport, { dispatch, rejectWithValue }) => {
        try {
            const response = await exportCSV(params);
            dispatch(setDownloadCSV(response.data));      
            let startDate =params.start_time? params.start_time:moment().startOf('month').format('YYYY-MM-DD');
            let endDate =params.end_time?params.end_time:moment(new Date()).format("YYYY-MM-DD");
            downloadCsv(response.data.data,startDate,endDate);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchExportChargerHistoryCSV = createAsyncThunk(
    '/admin/charger/:id/export',
    async (params: { data: CSVexport; id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await exportChargerHistoryCSV(params?.data, params?.id);
            dispatch(setDownloadChargerCSV(response.data));
            let startDate =moment().startOf('month').format('YYYY-MM-DD');
            let endDate =moment(new Date()).format("YYYY-MM-DD");   
            downloadCsv(response.data.data,startDate,endDate);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getListBookingCharger = createAsyncThunk('admin/booking', async (params: BookingList, { dispatch, rejectWithValue }) => {
    try {
        const response = await getListBooking(params);
        dispatch(setBookingList(response.data));
        return response.data;
    } catch (error: any) {
        dispatch(setBookingList(error));
        return rejectWithValue(getErrorMessage(error));
    }
});

export const cancelBookingCharger = createAsyncThunk(
    'admin/booking/:id/cancel',
    async (params: CancelBooking, { dispatch, rejectWithValue }) => {
        try {
            const response = await cancelBookingReservationList(params);
            dispatch(setCancelBookingList(response.data));
            return response.data;
        } catch (error: any) {
            dispatch(setCancelBookingList(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateMasterSlaveFlag = createAsyncThunk(
    'admin/charger/:id/update-master-slave-flag',
    async (params: UpdateMasterSlave, { rejectWithValue }) => {
        try {
            const response = await updateMasterSlaveFlagCharger(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListErrorsStatus = createAsyncThunk(
    'admin/error-status',
    async (params: ErrorsStatusParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListErrorsStatus(params);
            dispatch(setErrorsStatus(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListErrorsLog = createAsyncThunk(
    'admin/charger/:id/error-log',
    async (params: ErrorsLogParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListErrorsLog({ day_search: params.day_search, error_status_id: params.error_status_id }, params.id);
            const {
                data,
                total = 0,
                perPage = Constant.PAGE_SIZE,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
            } = response.data;
            dispatch(setErrorsLogs({ data, pagination: { total, perPage, currentPage, lastPage } }));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchChargerModel = createAsyncThunk(
    'admin/charger-model',
    async (params: ChargerModelParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await getChargerModel(params);

            dispatch(setChargerModel(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateStatus = createAsyncThunk(
    'admin/charger/:id/update-status',
    async (params: { data: UpdateStatusCharger; id?: number | string }, { rejectWithValue }) => {
        try {
            const response = await updateStatusCharger(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchStopCharter = createAsyncThunk(
    'admin/charger/:id/stop',
    async (params: TurnOnOff, { dispatch, rejectWithValue }) => {
        try {
            const response = await stopCharger(params);

            dispatch(setStopCharger(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchOpenCharter = createAsyncThunk(
    'admin/charger/:id/open',
    async (params: TurnOnOff, { dispatch, rejectWithValue }) => {
        try {
            const response = await openCharger(params);

            dispatch(setOpenCharger(response.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchItemPlaceStatistic = createAsyncThunk(
    'admin/place/:id/statistic',
    async (params: { id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getItemPlaceStatistic(params);
            const { data } = response?.data;
            dispatch(setItemPlaceStatistic(data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListChargingHistory = createAsyncThunk(
    'charging-history',
    async (params: any, { dispatch, rejectWithValue }) => {
        try {
            console.log(params)
            const response = await fetchChargingHistory(params);
            const {
                data = [],
                total = 0,
                per_page: perPage = Constant.PAGE_SIZE,
                current_page: currentPage = Constant.DEFAULT_PAGE,
                last_page: lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            dispatch(
                setListChargingHistory({
                    chargerHistory: data,
                    pagination: { total, perPage, currentPage, lastPage },
                })
            );
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListChargingHistoryByCharger = createAsyncThunk(
    'admin/:id/charging-history',
    async (params: { data: ChargingHistory; id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetchChargingHistoryByCharger(params.data, params.id);
            const {
                data = [],
                total = 0,
                per_page: perPage = Constant.PAGE_SIZE,
                current_page: currentPage = Constant.DEFAULT_PAGE,
                last_page: lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            dispatch(
                setListHistory({
                    chargerHistory: data,
                    pagination: { total, perPage, currentPage, lastPage },
                })
            );
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchStatisticHistoryByCharger = createAsyncThunk(
    'admin/charger/:id/statistic-charging-history',
    async (params: { data: ListStatisticRequestByCharger; id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getStatisticHistoryByCharger(params.data, params.id);
            dispatch(setStatisticHistoryByCharger(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// NITTO

export const fetchNittoChargerStatus = createAsyncThunk(
    'admin/charger/:id/status-nitto',
    async (params: {id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let res = await getStatusNitto(params.id);
            const { success } = res?.data;
            if (success) {
                dispatch(setStatusNitto(res.data));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListStatusNitto = createAsyncThunk(
    'admin/charger/:id/list-status-nitto',
    async (params: { id?: number | string; pageNitto?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListStatusNitto(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE_NEW,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListStatusNitto({ nittoData: data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListStatusNitto(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const updateConnectNitto = createAsyncThunk(
    '/admin/charger/:id/connect-nitto',
    async (params: { data: SendConnectNitto; id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await connectNitto(params);

            dispatch(setConnectNitto(response?.data));
        } catch (error: any) {
            dispatch(setConnectNitto(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListSupplyStatus = createAsyncThunk(
    'admin/charger/:id/list-supply-status',
    async (params: { id?: number | string; pageSupply?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListSupplyStatus(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE_NEW,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListSupplyStatus({ supplyStatusData: data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListSupplyStatus(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const asyncStartNitto = createAsyncThunk(
    'admin/charger/:id/start-nitto',
    async (params: { data: NittoChargerStatus; id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let res = await startNitto(params.data, params.id);
            const { success } = res?.data;
            if (success) {
                dispatch(setStartNitto(res?.data));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const asyncStopNitto = createAsyncThunk(
    'admin/charger/:id/stop-nitto',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let res = await stopNitto(params.id);
            const { success } = res?.data;
            if (success) {
                dispatch(setStopNitto(res?.data));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchInfoNitto = createAsyncThunk(
    'admin/charger/:id/info-nitto',
    async (params: { id?: number | string; pageSupply?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getInfoNitto(params);
            dispatch(setInfoNitto(response.data));
        } catch (error: any) {
            dispatch(setInfoNitto(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

// KAZAM

export const fetchKazamChargerStatus = createAsyncThunk(
    'admin/charger/:id/status-kazam-charger',
    async (params: {id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let res = await getStatusKazamCharger(params.id);
            const { success } = res?.data;
            if (success) {
                dispatch(setStatusKazamCharger(res.data));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchKazamChargingStatus = createAsyncThunk(
    'admin/charger/:id/status-kazam-charging',
    async (params: {id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let res = await getStatusKazamCharging(params.id);
            const { success } = res?.data;
            if (success) {
                dispatch(setStatusKazamCharging(res.data));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListStatusKazamCharger = createAsyncThunk(
    'admin/charger/:id/list-status-kazam-charger',
    async (params: { id?: number | string; pageKazamCharger?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListStatusKazamCharger(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE_NEW,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListStatusKazamCharger({ kazamChargerData: data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListStatusKazamCharger(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const fetchListStatusKazamCharging = createAsyncThunk(
    'admin/charger/:id/list-status-kazam-charging',
    async (params: { id?: number | string; pageKazamCharging?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListStatusKazamCharging(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE_NEW,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListStatusKazamCharging({ kazamData: data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListStatusKazamCharging(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const updateConnectKazam = createAsyncThunk(
    '/admin/charger/:id/connect-kazam',
    async (params: { data: SendConnectKazam; id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await connectKazam(params);

            dispatch(setConnectKazam(response?.data));
        } catch (error: any) {
            dispatch(setConnectKazam(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListKazamSupplyStatus = createAsyncThunk(
    'admin/charger/:id/list-kazam-supply-status',
    async (params: { id?: number | string; pageSupply?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListKazamSupplyStatus(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE_NEW,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListKazamSupplyStatus({ supplyKazamStatusData: data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListKazamSupplyStatus(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const asyncStartKazam = createAsyncThunk(
    'admin/charger/:id/start-kazam-charging',
    async (params: { data: KazamChargerStatus; id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let res = await startKazam(params.data, params.id);
            const { success } = res?.data;
            if (success) {
                dispatch(setStartKazam(res?.data));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const asyncStopKazam = createAsyncThunk(
    'admin/charger/:id/stop-kazam-charging',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let res = await stopKazam(params.id);
            const { success } = res?.data;
            if (success) {
                dispatch(setStopKazam(res?.data));
            }
            return success;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchInfoKazam = createAsyncThunk(
    'admin/charger/:id/info-kazam',
    async (params: { id?: number | string; pageSupply?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getInfoKazam(params);
            dispatch(setInfoKazam(response.data));
        } catch (error: any) {
            dispatch(setInfoKazam(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const chargerSlice = createSlice({
    name: 'listCharger',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        createCharger: [],
        placeItemStatistic: {
            error: false,
            loading: false,
            success: false,
            message: '',
            data: {} as PlaceItemStatistic,
        },
        register: {
            loading: false,
            success: false,
            error: false,
            errors: '',
            message: '',
        },
        update: {
            success: false,
            error: false,
            errors: '',
            messages: '',
            loading: false,
        },
        stopCharger: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        openCharger: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        listCharger: {
            error: false,
            loading: false,
            success: false,
            chargers: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        listStatusNitto: {
            error: false,
            loading: false,
            success: false,
            nittoData: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        listSupplyStatus: {
            error: false,
            loading: false,
            success: false,
            supplyStatusData: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        listStatusKazamCharger: {
            error: false,
            loading: false,
            success: false,
            kazamChargerData: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        listStatusKazamCharging: {
            error: false,
            loading: false,
            success: false,
            kazamData: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        listKazamSupplyStatus: {
            error: false,
            loading: false,
            success: false,
            supplyKazamStatusData: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        detailCharger: {
            success: false,
            message: '',
            data: {} as CreateChargerData,
            loading: false,
            error: false,
        },
        listSearchPlace: {
            error: false,
            loading: false,
            success: false,
            places: [],
        },
        listChargerByPlace: {
            error: false,
            loading: false,
            success: false,
            listPlaces: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        downloadCSVData: {
            error: false,
            loading: false,
            success: false,
            data: '',
        },
        downloadCSVChargerData: {
            error: false,
            loading: false,
            success: false,
            data: '',
        },
        masterChargerMaker: {
            error: false,
            loading: false,
            success: false,
            data: [],
        },
        masterChargerType: {
            error: false,
            loading: false,
            success: false,
            data: [],
        },
        masterChargerMakerType: {
            error: false,
            loading: false,
            success: false,
            data: [],
        },
        masterChargerStatus: {
            error: false,
            loading: false,
            success: false,
            data: [],
        },
        masterChargerOperationStatus: {
            error: false,
            loading: false,
            success: false,
            data: [],
        },
        listBooking: {
            error: false,
            loading: false,
            success: false,
            message: '',
            data: [],
        },
        cancelBooking: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        updateMasterSlave: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        newCharger: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        errorsStatus: {
            loading: false,
            success: false,
            error: false,
            data: [] as ErrorsStatus[],
        },
        errorsLogs: {
            loading: false,
            success: false,
            error: false,
            message: '',
            data: [] as ErrorLog[],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        updateStatus: {
            success: false,
            error: false,
            message: '',
            loading: false,
        },
        chargerModel: {
            success: false,
            error: false,
            loading: false,
            data: [] as ChargerModel[],
        },
        listChargingHistory: {
            error: false,
            loading: false,
            success: false,
            chargerHistory: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
            status: Constant.DEFAULT_STATUS,
        },
        listHistory: {
            error: false,
            loading: false,
            success: false,
            chargerHistory: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
            status: Constant.DEFAULT_STATUS,
        },
        chargerBooking: {
            success: false,
            message: '',
            data: { charger_history: {}, booking_reserved: {} } as ChargerBooking,
            loading: false,
            error: false,
        },
        statusNitto: {
            success: false,
            message: '',
            data: {} as NittoStatus,
            loading: false,
            error: false,
        },
        connectNitto: {
            success: false,
            message: '',
            data: {} as ConnectNitto,
            loading: false,
            error: false,
        },
        startNitto: {
            success: false,
            message: '',
            data: {} as ConnectNitto,
            loading: false,
            error: false,
        },
        stopNitto: {
            success: false,
            message: '',
            data: {} as ConnectNitto,
            loading: false,
            error: false,
        },
        infoNitto: {
            success: false,
            message: '',
            data: {} as SendConnectNitto,
            loading: false,
            error: false,
        },
        statusKazamCharger: {
            success: false,
            message: '',
            data: {} as KazamStatusCharger,
            loading: false,
            error: false,
        },
        statusKazamCharging: {
            success: false,
            message: '',
            data: {} as KazamStatusCharging,
            loading: false,
            error: false,
        },
        connectKazam: {
            success: false,
            message: '',
            data: {} as ConnectKazam,
            loading: false,
            error: false,
        },
        startKazam: {
            success: false,
            message: '',
            data: {} as ConnectKazam,
            loading: false,
            error: false,
        },
        stopKazam: {
            success: false,
            message: '',
            data: {} as ConnectKazam,
            loading: false,
            error: false,
        },
        infoKazam: {
            success: false,
            message: '',
            data: {} as SendConnectKazam,
            loading: false,
            error: false,
        },
    } as ChargerState,
    reducers: {
        setListCharger: (state: ChargerState, { payload }) => {
            const { chargers, pagination } = payload;
            state.listCharger.chargers = chargers;
            state.listCharger.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setNewCharger: (state, action) => {
            state.createCharger = action?.payload ?? [];
            state.register.errors = action?.payload?.response?.data?.errors;
            state.status = action?.payload?.response?.status;
        },
        setEditCharger: (state, action) => {
            state.createCharger = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
            state.update.errors = action?.payload?.response?.data?.errors;
        },
        resetUpdateState: (state: ChargerState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.update = {
                loading: false,
                success: false,
                error: false,
                errors: '',
                messages: '',
            };
        },
        resetRegisterState: (state: ChargerState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.register = {
                success: false,
                message: '',
                loading: false,
                error: false,
                errors: '',
            };
        },
        setStopCharger: (state: ChargerState, { payload }) => {
            state.stopCharger.success = payload?.success;
            state.status = payload?.code;
        },
        resetStopCharger: (state: ChargerState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.stopCharger = {
                success: false,
                messages: '',
                error: false,
                loading: false,
            };
        },
        setOpenCharger: (state: ChargerState, { payload }) => {
            state.openCharger.success = payload?.success;
            state.status = payload?.code;
        },
        resetOpenCharger: (state: ChargerState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.openCharger = {
                success: false,
                messages: '',
                error: false,
                loading: false,
            };
        },
        setDetailCharger: (state: ChargerState, { payload }) => {
            state.detailCharger.data = payload?.data;
            state.detailCharger.success = payload?.success;
            state.detailCharger.message = payload?.message;
            state.status = payload?.response?.status;
        },
        resetDetailCharger: (state: ChargerState) => {
            state.detailCharger.data = {} as CreateChargerData;
            state.detailCharger.success = false;
            state.detailCharger.loading = false;
            state.detailCharger.error = false;
            state.detailCharger.message = '';
        },
        setListSearchPlaceCharger: (state: ChargerState, { payload }) => {
            const { places } = payload;
            state.listSearchPlace.places = places;
            state.status = payload?.response?.status;
        },
        setListChargerByPlace: (state: ChargerState, { payload }) => {
            const { chargers, pagination } = payload;
            state.listChargerByPlace.listPlaces = chargers;
            state.listChargerByPlace.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setDownloadCSV: (state, { payload }) => {
            state.downloadCSVData.data = payload;
            state.downloadCSVData.success = payload?.success;
        },
        setDownloadChargerCSV: (state, { payload }) => {
            state.downloadCSVData.data = payload;
            state.downloadCSVData.success = payload?.success;
        },
        setChargerStatus: (state: ChargerState, { payload }) => {
            state.masterChargerStatus.data = payload;
        },
        setChargerType: (state: ChargerState, { payload }) => {
            state.masterChargerType.data = payload;
        },
        setChargerMaker: (state: ChargerState, { payload }) => {
            state.masterChargerMaker.data = payload;
        },
        setChargerOperationStatus: (state: ChargerState, { payload }) => {
            state.masterChargerOperationStatus.data = payload;
        },
        setBookingList: (state: ChargerState, { payload }) => {
            state.listBooking.data = payload?.data;
            state.listBooking.success = payload?.success;
            state.listBooking.message = payload?.message;
            state.status = payload?.response?.status;
        },
        setCancelBookingList: (state: ChargerState, { payload }) => {
            state.listBooking.data = payload?.data;
            state.listBooking.success = payload?.success;
            state.listBooking.message = payload?.message;
            state.status = payload?.response?.status;
        },
        setUpdateMasterSlave: (state: ChargerState, { payload }) => {
            state.updateMasterSlave.success = payload?.success;
            state.updateMasterSlave.message = payload?.message;
            state.status = payload?.response?.status;
        },
        setErrorsStatus: (state: ChargerState, { payload }) => {
            state.errorsStatus.data = payload?.data;
            state.errorsStatus.success = payload?.success;
            state.status = payload?.response?.status;
        },

        setErrorsLogs: (state: ChargerState, { payload }) => {
            state.errorsLogs.data = payload?.data;
            state.errorsLogs.pagination = payload?.pagination;
            state.errorsLogs.success = payload?.success;
            state.errorsLogs.message = payload?.message;
            state.status = payload?.response?.status;
        },
        setUpdateStatus: (state: ChargerState, { payload }) => {
            state.updateStatus.success = payload?.success;
            state.updateStatus.message = payload?.message;
            state.status = payload?.response?.status;
        },
        resetUpdateStatusState: (state: ChargerState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.updateStatus = {
                loading: false,
                success: false,
                error: false,
                message: '',
            };
        },
        setChargerModel: (state: ChargerState, { payload }) => {
            state.chargerModel.data = payload?.data;
            state.chargerModel.success = payload?.success;
        },
        setItemPlaceStatistic: (state: ChargerState, { payload }) => {
            state.placeItemStatistic.data = payload;
        },
        setListChargingHistory: (state: ChargerState, { payload }) => {
            const { chargerHistory,totalCompletedCount, pagination } = payload;
            state.listChargingHistory.chargerHistory = chargerHistory;
            state.listChargingHistory.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setChargerMarkerType: (state: ChargerState, { payload }) => {
            state.masterChargerMakerType.data = payload;
        },
        setListHistory: (state: ChargerState, { payload }) => {
            const { chargerHistory, pagination } = payload;
            state.listHistory.chargerHistory = chargerHistory;
            state.listHistory.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setStatisticHistoryByCharger: (state: ChargerState, { payload }) => {
            state.chargerBooking.data = payload?.data;
            state.chargerBooking.success = payload?.success;
            state.chargerBooking.message = payload?.message;
        },
        setStatusNitto: (state: ChargerState, { payload }) => {
            state.statusNitto.data = payload?.data;
            state.statusNitto.success = payload?.success;
            state.statusNitto.message = payload?.message;
        },
        setConnectNitto: (state: ChargerState, { payload }) => {
            state.connectNitto.data = payload?.data;
            state.connectNitto.success = payload?.success;
            state.connectNitto.message = payload?.message;
        },
        setListStatusNitto: (state: ChargerState, { payload }) => {
            const { nittoData, pagination } = payload;
            state.listStatusNitto.nittoData = nittoData;
            state.listStatusNitto.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setListSupplyStatus: (state: ChargerState, { payload }) => {
            const { supplyStatusData, pagination } = payload;
            state.listSupplyStatus.supplyStatusData = supplyStatusData;
            state.listSupplyStatus.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setStartNitto: (state: ChargerState, { payload }) => {
            state.startNitto.data = payload?.data;
            state.startNitto.success = payload?.success;
            state.startNitto.message = payload?.message;
        },
        setStopNitto: (state: ChargerState, { payload }) => {
            state.stopNitto.data = payload?.data;
            state.stopNitto.success = payload?.success;
            state.stopNitto.message = payload?.message;
        },
        setInfoNitto: (state: ChargerState, { payload }) => {
            state.infoNitto.data = payload?.data;
            state.infoNitto.success = payload?.success;
            state.infoNitto.message = payload?.message;
        },
        setStatusKazamCharger: (state: ChargerState, { payload }) => {
            state.statusKazamCharger.data = payload?.data;
            state.statusKazamCharger.success = payload?.success;
            state.statusKazamCharger.message = payload?.message;
        },
        setStatusKazamCharging: (state: ChargerState, { payload }) => {
            state.statusKazamCharging.data = payload?.data;
            state.statusKazamCharging.success = payload?.success;
            state.statusKazamCharging.message = payload?.message;
        },
        setConnectKazam: (state: ChargerState, { payload }) => {
            state.connectKazam.data = payload?.data;
            state.connectKazam.success = payload?.success;
            state.connectKazam.message = payload?.message;
        },
        setListStatusKazamCharger: (state: ChargerState, { payload }) => {
            const { kazamData, pagination } = payload;
            state.listStatusKazamCharger.kazamChargerData = kazamData;
            state.listStatusKazamCharger.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setListStatusKazamCharging: (state: ChargerState, { payload }) => {
            const { kazamData, pagination } = payload;
            state.listStatusKazamCharging.kazamData = kazamData;
            state.listStatusKazamCharging.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setListKazamSupplyStatus: (state: ChargerState, { payload }) => {
            const { supplyStatusData, pagination } = payload;
            state.listKazamSupplyStatus.supplyKazamStatusData = supplyStatusData;
            state.listKazamSupplyStatus.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setStartKazam: (state: ChargerState, { payload }) => {
            state.startKazam.data = payload?.data;
            state.startKazam.success = payload?.success;
            state.startKazam.message = payload?.message;
        },
        setStopKazam: (state: ChargerState, { payload }) => {
            state.stopKazam.data = payload?.data;
            state.stopKazam.success = payload?.success;
            state.stopKazam.message = payload?.message;
        },
        setInfoKazam: (state: ChargerState, { payload }) => {
            state.infoKazam.data = payload?.data;
            state.infoKazam.success = payload?.success;
            state.infoKazam.message = payload?.message;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListCharger.pending, (state: ChargerState) => {
                state.listCharger.loading = true;
            })
            .addCase(fetchListCharger.rejected, (state: ChargerState) => {
                state.listCharger.loading = false;
                state.listCharger.success = false;
                state.listCharger.error = true;
            })
            .addCase(fetchListCharger.fulfilled, (state: ChargerState) => {
                state.listCharger.loading = false;
                state.listCharger.success = true;
                state.listCharger.error = false;
            })
            .addCase(createMyCharger.pending, (state: ChargerState) => {
                state.register.loading = true;
            })
            .addCase(createMyCharger.rejected, (state: ChargerState, { payload }) => {
                state.register.loading = false;
                state.register.success = false;
                state.register.error = true;
                state.register.message = payload as string | RegisterChargerValidate;
            })
            .addCase(createMyCharger.fulfilled, (state: ChargerState) => {
                state.register.loading = false;
                state.register.success = true;
                state.register.error = false;
            })
            .addCase(editMyCharger.pending, (state: ChargerState) => {
                state.update.loading = true;
            })
            .addCase(editMyCharger.rejected, (state: ChargerState, { payload }) => {
                state.update.loading = false;
                state.update.success = false;
                state.update.error = true;
                state.update.messages = payload as string | RegisterChargerValidate;
            })
            .addCase(editMyCharger.fulfilled, (state: ChargerState) => {
                state.update.loading = false;
                state.update.success = true;
                state.update.error = false;
            })
            .addCase(getDetailChargerData.pending, (state: ChargerState) => {
                state.detailCharger.loading = true;
            })
            .addCase(getDetailChargerData.rejected, (state: ChargerState, { payload }) => {
                state.detailCharger.loading = false;
                state.detailCharger.success = false;
                state.detailCharger.error = true;
                state.detailCharger.message = payload as string;
            })
            .addCase(getDetailChargerData.fulfilled, (state: ChargerState) => {
                state.detailCharger.loading = false;
                state.detailCharger.success = true;
                state.detailCharger.error = false;
            })
            .addCase(fetchListSearchPlace.pending, (state: ChargerState) => {
                state.listSearchPlace.loading = true;
            })
            .addCase(fetchListSearchPlace.rejected, (state: ChargerState) => {
                state.listSearchPlace.loading = false;
                state.listSearchPlace.success = false;
                state.listSearchPlace.error = true;
            })
            .addCase(fetchListSearchPlace.fulfilled, (state: ChargerState) => {
                state.listSearchPlace.loading = false;
                state.listSearchPlace.success = true;
                state.listSearchPlace.error = false;
            })
            .addCase(fetchListChargerByPlace.pending, (state: ChargerState) => {
                state.listChargerByPlace.loading = true;
            })
            .addCase(fetchListChargerByPlace.rejected, (state: ChargerState) => {
                state.listChargerByPlace.loading = false;
                state.listChargerByPlace.success = false;
                state.listChargerByPlace.error = true;
                state.listChargerByPlace.listPlaces = [];
                state.listChargerByPlace.pagination = {
                    total: 0,
                    perPage: Constant.PAGE_SIZE,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                };
            })
            .addCase(fetchListChargerByPlace.fulfilled, (state: ChargerState) => {
                state.listChargerByPlace.loading = false;
                state.listChargerByPlace.success = true;
                state.listChargerByPlace.error = false;
            })
            .addCase(fetchChargerStatus.pending, (state: ChargerState) => {
                state.masterChargerStatus.loading = true;
            })
            .addCase(fetchChargerStatus.rejected, (state: ChargerState) => {
                state.masterChargerStatus.loading = false;
                state.masterChargerStatus.success = false;
                state.masterChargerStatus.error = true;
            })
            .addCase(fetchChargerStatus.fulfilled, (state: ChargerState) => {
                state.masterChargerStatus.loading = false;
                state.masterChargerStatus.success = true;
                state.masterChargerStatus.error = false;
            })

            .addCase(fetchChargerMaker.pending, (state: ChargerState) => {
                state.masterChargerMaker.loading = true;
            })
            .addCase(fetchChargerMaker.rejected, (state: ChargerState) => {
                state.masterChargerMaker.loading = false;
                state.masterChargerMaker.success = false;
                state.masterChargerMaker.error = true;
            })
            .addCase(fetchChargerMaker.fulfilled, (state: ChargerState) => {
                state.masterChargerMaker.loading = false;
                state.masterChargerMaker.success = true;
                state.masterChargerMaker.error = false;
            })

            .addCase(fetchChargerOperationStatus.pending, (state: ChargerState) => {
                state.masterChargerOperationStatus.loading = true;
            })
            .addCase(fetchChargerOperationStatus.rejected, (state: ChargerState) => {
                state.masterChargerOperationStatus.loading = false;
                state.masterChargerOperationStatus.success = false;
                state.masterChargerOperationStatus.error = true;
            })
            .addCase(fetchChargerOperationStatus.fulfilled, (state: ChargerState) => {
                state.masterChargerOperationStatus.loading = false;
                state.masterChargerOperationStatus.success = true;
                state.masterChargerOperationStatus.error = false;
            })

            .addCase(fetchChargerType.pending, (state: ChargerState) => {
                state.masterChargerType.loading = true;
            })
            .addCase(fetchChargerType.rejected, (state: ChargerState) => {
                state.masterChargerType.loading = false;
                state.masterChargerType.success = false;
                state.masterChargerType.error = true;
            })
            .addCase(fetchChargerType.fulfilled, (state: ChargerState) => {
                state.masterChargerType.loading = false;
                state.masterChargerType.success = true;
                state.masterChargerType.error = false;
            })
            .addCase(getListBookingCharger.pending, (state: ChargerState) => {
                state.listBooking.loading = true;
            })
            .addCase(getListBookingCharger.rejected, (state: ChargerState, { payload }) => {
                state.listBooking.loading = false;
                state.listBooking.success = false;
                state.listBooking.error = true;
                state.listBooking.message = payload as string;
            })
            .addCase(getListBookingCharger.fulfilled, (state: ChargerState) => {
                state.listBooking.loading = false;
                state.listBooking.success = true;
                state.listBooking.error = false;
            })
            .addCase(exportChargerCSV.pending, (state: ChargerState) => {
                state.downloadCSVData.loading = true;
            })
            .addCase(exportChargerCSV.rejected, (state: ChargerState) => {
                state.downloadCSVData.loading = false;
                state.downloadCSVData.success = false;
                state.downloadCSVData.error = true;
            })
            .addCase(exportChargerCSV.fulfilled, (state: ChargerState) => {
                state.downloadCSVData.loading = false;
                state.downloadCSVData.success = true;
                state.downloadCSVData.error = false;
            })
            .addCase(fetchExportChargerHistoryCSV.pending, (state: ChargerState) => {
                state.downloadCSVChargerData.loading = true;
            })
            .addCase(fetchExportChargerHistoryCSV.rejected, (state: ChargerState) => {
                state.downloadCSVChargerData.loading = false;
                state.downloadCSVChargerData.success = false;
                state.downloadCSVChargerData.error = true;
            })
            .addCase(fetchExportChargerHistoryCSV.fulfilled, (state: ChargerState) => {
                state.downloadCSVChargerData.loading = false;
                state.downloadCSVChargerData.success = true;
                state.downloadCSVChargerData.error = false;
            })
            .addCase(cancelBookingCharger.pending, (state: ChargerState) => {
                state.cancelBooking.loading = true;
            })
            .addCase(cancelBookingCharger.rejected, (state: ChargerState) => {
                state.cancelBooking.loading = false;
                state.cancelBooking.success = false;
                state.cancelBooking.error = true;
            })
            .addCase(cancelBookingCharger.fulfilled, (state: ChargerState) => {
                state.cancelBooking.loading = false;
                state.cancelBooking.success = true;
                state.cancelBooking.error = false;
            })
            .addCase(updateMasterSlaveFlag.pending, (state: ChargerState) => {
                state.updateMasterSlave.loading = true;
            })
            .addCase(updateMasterSlaveFlag.rejected, (state: ChargerState) => {
                state.cancelBooking.loading = false;
                state.cancelBooking.success = false;
                state.cancelBooking.error = true;
            })
            .addCase(updateMasterSlaveFlag.fulfilled, (state: ChargerState) => {
                state.updateMasterSlave.success = true;
                state.updateMasterSlave.error = false;
            })
            .addCase(fetchListErrorsStatus.pending, (state: ChargerState) => {
                state.errorsStatus.loading = true;
            })
            .addCase(fetchListErrorsStatus.rejected, (state: ChargerState) => {
                state.errorsStatus.loading = false;
                state.errorsStatus.success = false;
                state.errorsStatus.error = true;
            })
            .addCase(fetchListErrorsStatus.fulfilled, (state: ChargerState) => {
                state.errorsStatus.success = true;
                state.errorsStatus.loading = false;
                state.errorsStatus.error = false;
            })
            .addCase(fetchListErrorsLog.pending, (state: ChargerState) => {
                state.errorsLogs.loading = true;
            })
            .addCase(fetchListErrorsLog.rejected, (state: ChargerState) => {
                state.errorsLogs.loading = false;
                state.errorsLogs.success = false;
                state.errorsLogs.error = true;
            })
            .addCase(fetchListErrorsLog.fulfilled, (state: ChargerState) => {
                state.errorsLogs.loading = false;
                state.errorsLogs.success = true;
                state.errorsLogs.error = false;
            })
            .addCase(updateStatus.pending, (state: ChargerState) => {
                state.updateStatus.loading = true;
            })
            .addCase(updateStatus.rejected, (state: ChargerState) => {
                state.updateStatus.loading = false;
                state.updateStatus.success = false;
                state.updateStatus.error = true;
            })
            .addCase(updateStatus.fulfilled, (state: ChargerState) => {
                state.updateStatus.loading = false;
                state.updateStatus.success = true;
                state.updateStatus.error = false;
            })
            .addCase(fetchChargerModel.pending, (state: ChargerState) => {
                state.chargerModel.loading = true;
            })
            .addCase(fetchChargerModel.rejected, (state: ChargerState) => {
                state.chargerModel.loading = false;
                state.chargerModel.success = false;
                state.chargerModel.error = true;
            })
            .addCase(fetchChargerModel.fulfilled, (state: ChargerState) => {
                state.chargerModel.loading = false;
                state.chargerModel.success = true;
                state.chargerModel.error = false;
            })
            .addCase(fetchItemPlaceStatistic.pending, (state: ChargerState) => {
                state.placeItemStatistic.loading = true;
            })
            .addCase(fetchPlaceStatistic.rejected, (state: ChargerState) => {
                state.placeItemStatistic.loading = false;
                state.placeItemStatistic.success = false;
                state.placeItemStatistic.error = true;
            })
            .addCase(fetchPlaceStatistic.fulfilled, (state: ChargerState) => {
                state.placeItemStatistic.loading = false;
                state.placeItemStatistic.success = true;
                state.placeItemStatistic.error = false;
            })

            .addCase(fetchListChargingHistory.pending, (state: ChargerState) => {
                state.listChargingHistory.loading = true;
            })
            .addCase(fetchListChargingHistory.rejected, (state: ChargerState) => {
                state.listChargingHistory.loading = false;
                state.listChargingHistory.success = false;
                state.listChargingHistory.error = true;
            })
            .addCase(fetchListChargingHistory.fulfilled, (state: ChargerState) => {
                state.listChargingHistory.loading = false;
                state.listChargingHistory.success = true;
                state.listChargingHistory.error = false;
            })
            .addCase(fetchChargerMakerType.pending, (state: ChargerState) => {
                state.masterChargerMakerType.loading = true;
            })
            .addCase(fetchChargerMakerType.rejected, (state: ChargerState) => {
                state.masterChargerMakerType.loading = false;
                state.masterChargerMakerType.success = false;
                state.masterChargerMakerType.error = true;
            })
            .addCase(fetchChargerMakerType.fulfilled, (state: ChargerState) => {
                state.masterChargerMakerType.loading = false;
                state.masterChargerMakerType.success = true;
                state.masterChargerMakerType.error = false;
            })
            .addCase(fetchListChargingHistoryByCharger.pending, (state: ChargerState) => {
                state.chargerBooking.loading = true;
            })
            .addCase(fetchListChargingHistoryByCharger.rejected, (state: ChargerState) => {
                state.chargerBooking.loading = false;
                state.chargerBooking.success = false;
                state.chargerBooking.error = true;
            })
            .addCase(fetchListChargingHistoryByCharger.fulfilled, (state: ChargerState) => {
                state.chargerBooking.loading = false;
                state.chargerBooking.success = true;
                state.chargerBooking.error = false;
            })
            .addCase(fetchStopCharter.pending, (state: ChargerState) => {
                state.stopCharger.loading = true;
            })
            .addCase(fetchStopCharter.rejected, (state: ChargerState) => {
                state.stopCharger.loading = false;
                state.stopCharger.success = false;
                state.stopCharger.error = true;
            })
            .addCase(fetchStopCharter.fulfilled, (state: ChargerState) => {
                state.stopCharger.loading = false;
                state.stopCharger.success = true;
                state.stopCharger.error = false;
            })
            .addCase(fetchOpenCharter.pending, (state: ChargerState) => {
                state.openCharger.loading = true;
            })
            .addCase(fetchOpenCharter.rejected, (state: ChargerState) => {
                state.openCharger.loading = false;
                state.openCharger.success = false;
                state.openCharger.error = true;
            })
            .addCase(fetchOpenCharter.fulfilled, (state: ChargerState) => {
                state.openCharger.loading = false;
                state.openCharger.success = false;
                state.openCharger.error = true;
            })
            .addCase(fetchNittoChargerStatus.pending, (state: ChargerState) => {
                state.statusNitto.loading = true;
            })
            .addCase(fetchNittoChargerStatus.rejected, (state: ChargerState) => {
                state.statusNitto.loading = false;
                state.statusNitto.success = false;
                state.statusNitto.error = true;
            })
            .addCase(fetchNittoChargerStatus.fulfilled, (state: ChargerState) => {
                state.statusNitto.loading = false;
                state.statusNitto.success = true;
                state.statusNitto.error = false;
            })
            .addCase(updateConnectNitto.pending, (state: ChargerState) => {
                state.connectNitto.loading = true;
            })
            .addCase(updateConnectNitto.rejected, (state: ChargerState, { payload }) => {
                state.connectNitto.loading = false;
                state.connectNitto.success = false;
                state.connectNitto.error = true;
                state.connectNitto.message = payload as string;
            })
            .addCase(updateConnectNitto.fulfilled, (state: ChargerState) => {
                state.connectNitto.loading = false;
                state.connectNitto.success = true;
                state.connectNitto.error = false;
            })
            .addCase(fetchListStatusNitto.pending, (state: ChargerState) => {
                state.listStatusNitto.loading = true;
            })
            .addCase(fetchListStatusNitto.rejected, (state: ChargerState) => {
                state.listStatusNitto.loading = false;
                state.listStatusNitto.success = false;
                state.listStatusNitto.error = true;
            })
            .addCase(fetchListStatusNitto.fulfilled, (state: ChargerState) => {
                state.listStatusNitto.loading = false;
                state.listStatusNitto.success = true;
                state.listStatusNitto.error = false;
            })
            .addCase(fetchListSupplyStatus.pending, (state: ChargerState) => {
                state.listSupplyStatus.loading = true;
            })
            .addCase(fetchListSupplyStatus.rejected, (state: ChargerState) => {
                state.listSupplyStatus.loading = false;
                state.listSupplyStatus.success = false;
                state.listSupplyStatus.error = true;
            })
            .addCase(fetchListSupplyStatus.fulfilled, (state: ChargerState) => {
                state.listSupplyStatus.loading = false;
                state.listSupplyStatus.success = true;
                state.listSupplyStatus.error = false;
            })
            .addCase(asyncStartNitto.pending, (state: ChargerState) => {
                state.startNitto.loading = true;
            })
            .addCase(asyncStartNitto.rejected, (state: ChargerState) => {
                state.startNitto.loading = false;
                state.startNitto.success = false;
                state.startNitto.error = true;
            })
            .addCase(asyncStartNitto.fulfilled, (state: ChargerState) => {
                state.startNitto.loading = false;
                state.startNitto.success = true;
                state.startNitto.error = false;
            })
            .addCase(asyncStopNitto.pending, (state: ChargerState) => {
                state.stopNitto.loading = true;
            })
            .addCase(asyncStopNitto.rejected, (state: ChargerState) => {
                state.stopNitto.loading = false;
                state.stopNitto.success = false;
                state.stopNitto.error = true;
            })
            .addCase(asyncStopNitto.fulfilled, (state: ChargerState) => {
                state.stopNitto.loading = false;
                state.stopNitto.success = true;
                state.stopNitto.error = false;
            })
            .addCase(fetchInfoNitto.pending, (state: ChargerState) => {
                state.infoNitto.loading = true;
            })
            .addCase(fetchInfoNitto.rejected, (state: ChargerState) => {
                state.infoNitto.loading = false;
                state.infoNitto.success = false;
                state.infoNitto.error = true;
            })
            .addCase(fetchInfoNitto.fulfilled, (state: ChargerState) => {
                state.infoNitto.loading = false;
                state.infoNitto.success = true;
                state.infoNitto.error = false;
            })
            .addCase(fetchKazamChargerStatus.pending, (state: ChargerState) => {
                state.statusKazamCharger.loading = true;
            })
            .addCase(fetchKazamChargerStatus.rejected, (state: ChargerState) => {
                state.statusKazamCharger.loading = false;
                state.statusKazamCharger.success = false;
                state.statusKazamCharger.error = true;
            })
            .addCase(fetchKazamChargerStatus.fulfilled, (state: ChargerState) => {
                state.statusKazamCharger.loading = false;
                state.statusKazamCharger.success = true;
                state.statusKazamCharger.error = false;
            })
            .addCase(fetchKazamChargingStatus.pending, (state: ChargerState) => {
                state.statusKazamCharging.loading = true;
            })
            .addCase(fetchKazamChargingStatus.rejected, (state: ChargerState) => {
                state.statusKazamCharger.loading = false;
                state.statusKazamCharging.success = false;
                state.statusKazamCharging.error = true;
            })
            .addCase(fetchKazamChargingStatus.fulfilled, (state: ChargerState) => {
                state.statusKazamCharging.loading = false;
                state.statusKazamCharging.success = true;
                state.statusKazamCharging.error = false;
            })
            .addCase(updateConnectKazam.pending, (state: ChargerState) => {
                state.connectKazam.loading = true;
            })
            .addCase(updateConnectKazam.rejected, (state: ChargerState, { payload }) => {
                state.connectKazam.loading = false;
                state.connectKazam.success = false;
                state.connectKazam.error = true;
                state.connectKazam.message = payload as string;
            })
            .addCase(updateConnectKazam.fulfilled, (state: ChargerState) => {
                state.connectKazam.loading = false;
                state.connectKazam.success = true;
                state.connectKazam.error = false;
            })
            .addCase(fetchListStatusKazamCharger.pending, (state: ChargerState) => {
                state.listStatusKazamCharger.loading = true;
            })
            .addCase(fetchListStatusKazamCharger.rejected, (state: ChargerState) => {
                state.listStatusKazamCharger.loading = false;
                state.listStatusKazamCharger.success = false;
                state.listStatusKazamCharger.error = true;
            })
            .addCase(fetchListStatusKazamCharger.fulfilled, (state: ChargerState) => {
                state.listStatusKazamCharger.loading = false;
                state.listStatusKazamCharger.success = true;
                state.listStatusKazamCharger.error = false;
            })
            .addCase(fetchListStatusKazamCharging.pending, (state: ChargerState) => {
                state.listStatusKazamCharging.loading = true;
            })
            .addCase(fetchListStatusKazamCharging.rejected, (state: ChargerState) => {
                state.listStatusKazamCharging.loading = false;
                state.listStatusKazamCharging.success = false;
                state.listStatusKazamCharging.error = true;
            })
            .addCase(fetchListStatusKazamCharging.fulfilled, (state: ChargerState) => {
                state.listStatusKazamCharging.loading = false;
                state.listStatusKazamCharging.success = true;
                state.listStatusKazamCharging.error = false;
            })
            .addCase(fetchListKazamSupplyStatus.pending, (state: ChargerState) => {
                state.listKazamSupplyStatus.loading = true;
            })
            .addCase(fetchListKazamSupplyStatus.rejected, (state: ChargerState) => {
                state.listKazamSupplyStatus.loading = false;
                state.listKazamSupplyStatus.success = false;
                state.listKazamSupplyStatus.error = true;
            })
            .addCase(fetchListKazamSupplyStatus.fulfilled, (state: ChargerState) => {
                state.listKazamSupplyStatus.loading = false;
                state.listKazamSupplyStatus.success = true;
                state.listKazamSupplyStatus.error = false;
            })
            .addCase(asyncStartKazam.pending, (state: ChargerState) => {
                state.startKazam.loading = true;
            })
            .addCase(asyncStartKazam.rejected, (state: ChargerState) => {
                state.startKazam.loading = false;
                state.startKazam.success = false;
                state.startKazam.error = true;
            })
            .addCase(asyncStartKazam.fulfilled, (state: ChargerState) => {
                state.startKazam.loading = false;
                state.startKazam.success = true;
                state.startKazam.error = false;
            })
            .addCase(asyncStopKazam.pending, (state: ChargerState) => {
                state.stopKazam.loading = true;
            })
            .addCase(asyncStopKazam.rejected, (state: ChargerState) => {
                state.stopKazam.loading = false;
                state.stopKazam.success = false;
                state.stopKazam.error = true;
            })
            .addCase(asyncStopKazam.fulfilled, (state: ChargerState) => {
                state.stopKazam.loading = false;
                state.stopKazam.success = true;
                state.stopKazam.error = false;
            })
            .addCase(fetchInfoKazam.pending, (state: ChargerState) => {
                state.infoKazam.loading = true;
            })
            .addCase(fetchInfoKazam.rejected, (state: ChargerState) => {
                state.infoKazam.loading = false;
                state.infoKazam.success = false;
                state.infoKazam.error = true;
            })
            .addCase(fetchInfoKazam.fulfilled, (state: ChargerState) => {
                state.infoKazam.loading = false;
                state.infoKazam.success = true;
                state.infoKazam.error = false;
            });
    },
});

export const ChargerSelector = (state: RootState) => state.chargers;
export const {
    setListCharger,
    setNewCharger,
    setEditCharger,
    resetRegisterState,
    resetUpdateState,
    setDetailCharger,
    setListSearchPlaceCharger,
    setListChargerByPlace,
    setDownloadCSV,
    setDownloadChargerCSV,
    setChargerStatus,
    setChargerType,
    setChargerMaker,
    setChargerOperationStatus,
    setBookingList,
    setCancelBookingList,
    setErrorsStatus,
    setErrorsLogs,
    resetUpdateStatusState,
    setChargerModel,
    setItemPlaceStatistic,
    setListChargingHistory,
    setChargerMarkerType,
    setListHistory,
    setStatisticHistoryByCharger,
    setOpenCharger,
    setStopCharger,
    resetStopCharger,
    resetOpenCharger,
    setStatusNitto,
    setConnectNitto,
    setListStatusNitto,
    setListSupplyStatus,
    setStartNitto,
    setStopNitto,
    setInfoNitto,
    setStatusKazamCharger,
    setStatusKazamCharging,
    setConnectKazam,
    setListStatusKazamCharger,
    setListStatusKazamCharging,
    setListKazamSupplyStatus,
    setStartKazam,
    setStopKazam,
    setInfoKazam,
    resetDetailCharger,
} = chargerSlice.actions;
