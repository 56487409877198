import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Constant} from 'config/constant';
import {RootState} from 'redux/store';
import {getErrorMessage,} from 'api';
import {
    RegisterFacilityTypeData
} from 'types';
import {createFacilityType, getListFacilityType, UpdateFacilityType} from 'api/facilityType';
import { Data } from '@react-google-maps/api';

export type facilityTypeData = {
    uuid: string,
    name: string
}
export type ListFacilityType = {
    error: boolean;
    loading: boolean;
    success: boolean;
    facilityType: facilityTypeData[];
    status: string;
};

export type FacilityTypeState = {
    status: number;
    facilityTypeData: facilityTypeData[];
    listFacilityType: ListFacilityType;
    detailsFacilityType: facilityTypeData;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | RegisterFacilityTypeData;
    };
    update: {
        success: boolean;
        error: boolean;
        messages: string | RegisterFacilityTypeData;
        loading: boolean;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    detail: {
        success: boolean;
        error: boolean;
        messages: string | RegisterFacilityTypeData;
        loading: boolean;
        dataDetail: facilityTypeData;
    };
}

export const createFacilityTypeA = createAsyncThunk(
    'admin/facility-type/store',
    async (params: RegisterFacilityTypeData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createFacilityType(params);
            const { data = {}, success } = response.data;
            if (success) {

                dispatch(setNewFacilityType(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setNewFacilityType(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateFacilityType = createAsyncThunk(
    '/admin/facility-type/update',
    async (params: { uuid: string, name: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await UpdateFacilityType(params);
            const { data = {}, success } = response.data;
            if (success) {
            dispatch(setUpdateFacilityType({params}));
                return true;
            }
        } catch (error: any) {
            dispatch(setUpdateFacilityType(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const facilityTypeSlice = createSlice(
    {
        name: 'facilityType',
        initialState: {
            status: Constant.DEFAULT_STATUS,
            facilityTypeData: [],
            register: {
                loading: false,
                success: false,
                error: false,
                message: '',
            },
            update: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            delete: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            listFacilityType: {
                error: false,
                loading: false,
                success: false,
                notification: [],
                pagination: {
                    total: 0,
                    perPage: Constant.PAGE_SIZE_NEW,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                },
                status: '',
            },
            listSearchPlace: {
                error: false,
                loading: false,
                success: false,
                places: [],
            }
        } as unknown as FacilityTypeState,
        reducers:{
            resetRegisterState: (state: FacilityTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.register = {
                    success: false,
                    loading: false,
                    message: '',
                    error: false,
                };
            },
            setNewFacilityType: (state, action) => {
                state.facilityTypeData = action?.payload ?? [];
                state.status = action?.payload?.response?.status;
            },
            setListFacilityType: (state: FacilityTypeState, { payload }) => {
               // const { facilityType, pagination } = payload;
               //state.listFacilityType.facilityType = facilityType;
                const { facilityType } = payload;


                state.listFacilityType.facilityType = payload ?? [];
            },
            setDetailsFacilityType:(state: FacilityTypeState, {payload}) => {
                const {detailsFacilityType} = payload;
                state.detailsFacilityType = {
                    name: payload.detailsFacilityType.name,
                    uuid: payload.detailsFacilityType.uuid
                }
            },
            setDeleteFacilityType: (state: FacilityTypeState, { payload }) => {
                const { facilityType } = payload;
                state.listFacilityType.facilityType = facilityType;
                state.listFacilityType.facilityType = payload?.response?.status;
            },
            setUpdateFacilityType: (state, action) => {
                state.facilityTypeData = action?.payload?.params ?? [];
                state.status = action?.payload?.response?.status;
            },
            resetUpdateState: (state: FacilityTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetDeleteState: (state: FacilityTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.delete = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetListFacilityTypeState: (state: FacilityTypeState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(createFacilityTypeA.pending, (state: FacilityTypeState) => {
                    state.register.loading = true;
                })
                .addCase(createFacilityTypeA.rejected, (state: FacilityTypeState, { payload }) => {
                    state.register.loading = false;
                    state.register.success = false;
                    state.register.error = true;
                    state.register.message = payload as string | RegisterFacilityTypeData;
                })
                .addCase(createFacilityTypeA.fulfilled, (state: FacilityTypeState) => {
                    state.register.loading = false;
                    state.register.success = true;
                    state.register.error = false;
                })
                .addCase(updateFacilityType.pending, (state: FacilityTypeState) => {
                    state.update.loading = true;
                })
                .addCase(updateFacilityType. rejected, (state: FacilityTypeState) => {
                    state.update.loading = false;
                    state.update.success = false;
                    state.update.error = true;
                })
                .addCase(updateFacilityType. fulfilled, (state: FacilityTypeState) => {
                    state.update.loading = false;
                    state.update.success = true;
                    state.update.error = true;
                })
        },
    }
);

export const fetchListFacilityType = createAsyncThunk(
    'admin/facility-type',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListFacilityType(params);
            const {
                data = [],
                success,
            } = response.data;


            if (success) {
                let m = 0;
                if(data.length>0) {
                    for (const elem of data) {
                        m+=1;
                        elem['no'] = m;
                    }
                }

               // dispatch(setListFacilityType({ FacilityType: data }));
                dispatch(setListFacilityType(data));

                return true;
            }
        } catch (error: any) {
            dispatch(setListFacilityType(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const {
    setListFacilityType,
    resetRegisterState,
    setNewFacilityType,
    setDeleteFacilityType,
    resetUpdateState,
    setUpdateFacilityType,
    resetDeleteState,
    resetListFacilityTypeState,
    setDetailsFacilityType
} = facilityTypeSlice.actions
export const facilityTypeSelector = (state: RootState) => state.facilityType;