import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    changePassword,
    createAdmin,
    deleteAdmin,
    detailAdmin,
    forgotPassword,
    getListAdmin,
    sendEmail, updateAdmin
} from 'api/account';
import { Constant } from 'config/constant';
import { RootState } from 'redux/store';
import { logout } from './authSlice';
import { message } from 'antd';

export type CreateAccountData = {
    email: string;
    name: string;
    group_id: number;
    facility_id: string;
    sent_mail: boolean;
};

export type DeleteAccountData = {
    email: string;
    facility_uuid: string;
};

export type UpdatePassword = {
    PreviousPassword: string;
    ProposedPassword: string;
};

export type sendEmailPassword = {
    email: string;
};

export type ResetPassword = {
    password: string;
    password_confirm: string;
    token: string;
    email: string;
};

type PlaceData = {
    id: number;
    building_name: number;
    code: string;
    name: string;
}

type DetailAccount = {
    id: number;
    name: string;
    email: string;
    role: string[];
    role_id: number;
    place: PlaceData;
};

type ListAccountData = {
    error: boolean;
    loading: boolean;
    success: boolean;
    data: DetailAccount[];
    pagination: {
        total: number;
        perPage: number;
        currentPage: number;
        lastPage: number;
    };
};

export type AccountState = {
    status: number;
    listAccount: ListAccountData;
    createAccount: CreateAccountData[];
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    detail: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
        dataDetail: DetailAccount;
    };
    update: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    changeAccount: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    sendEmailAccount: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: any;
    };
    resetAccount: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: any;
    };
};

export const getListAccount = createAsyncThunk(
    'admin/accounts',
    async (params: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListAdmin(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListAccount({ data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
        return false;
    }
);

export const changePasswordAccount = createAsyncThunk('change/password', async (params: UpdatePassword, { dispatch, rejectWithValue }) => {
    try {
        let response = await changePassword(params);
        const success = response.data;
        if (success) {
            dispatch(logout());
            message.success('The password change has been completed successfully. Please log in again with a new password.', 6);
        }
        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const sendEmailAccount = createAsyncThunk('send/email', async (params: sendEmailPassword, { rejectWithValue }) => {
    try {
        let response = await sendEmail(params);
        const success = response.data;
        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const resetPasswordAccount = createAsyncThunk('reset/password', async (params: ResetPassword, { dispatch, rejectWithValue }) => {
    try {
        let response = await forgotPassword(params);
        const success = response.data;
        return success;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const deleteAccount = createAsyncThunk(
    'admin/accounts/:id/delete',
    async (params: { facility_uuid : string; email: string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await deleteAdmin(params);
            const { data = [], success } = response.data;
            if (success) {
                return true;
            }
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getDetailAccount = createAsyncThunk(
    'admin/accounts/:id/detail',
    async (id: string | number, { dispatch, rejectWithValue }) => {
        try {
            const response = await detailAdmin(id);
            const { success } = response?.data;
            if (success) {
                dispatch(setDetailAccount(response?.data));
                return success;
            }
        } catch (error: any) {
            dispatch(setDetailAccount(error));
            return rejectWithValue(error);
        }
    }
);

export const updateAdminAccount = createAsyncThunk(
    '/admin/accounts/:id/update',
    async (params: { id?: number | string; data: { role_id: number } }, { dispatch, rejectWithValue }) => {
        try {
            let response = await updateAdmin(params);
            const { data = {}, success } = response.data;
            if (success) {
                return true;
            }
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createAdminAccount = createAsyncThunk(
    'admin/accounts/store',
    async (params: CreateAccountData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createAdmin(params);
            const {data = {}, success} = response.data;
            if (success) {
                dispatch(setNewAccount(data));
                return true;
            }
            return false;
        } catch (error: any) {
            dispatch(setNewAccount(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const accountSlice = createSlice({
    name: 'account',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        createAccount: [],
        listAccount: {
            error: false,
            loading: false,
            success: false,
            data: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        register: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        delete: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        detail: {
            success: false,
            error: false,
            messages: '',
            loading: false,
            dataDetail: {} as DetailAccount,
        },
        update: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        changeAccount: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        sendEmailAccount: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        resetAccount: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
    } as AccountState,
    reducers: {
        setListAccount: (state: AccountState, { payload }) => {
            const { data, pagination } = payload;
            state.listAccount.data = data;
            state.listAccount.pagination = pagination;
            state.status = payload?.response?.status;
        },

        resetRegisterState: (state: AccountState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.register = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        setNewAccount: (state, action) => {
            state.createAccount = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        resetDeleteState: (state: AccountState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.delete = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        resetUpdateState: (state: AccountState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.update = {
                loading: false,
                success: false,
                error: false,
                message: '',
            };
        },
        setDetailAccount: (state: AccountState, { payload }) => {
            state.detail.dataDetail = payload?.data;
            state.detail.success = payload?.success;
            state.detail.messages = payload?.message;
            state.status = payload?.response?.status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListAccount.pending, (state: AccountState) => {
                state.listAccount.loading = true;
            })
            .addCase(getListAccount.rejected, (state: AccountState) => {
                state.listAccount.loading = false;
                state.listAccount.success = false;
                state.listAccount.error = true;
            })
            .addCase(getListAccount.fulfilled, (state: AccountState) => {
                state.listAccount.loading = false;
                state.listAccount.success = true;
                state.listAccount.error = false;
            })
            .addCase(createAdminAccount.pending, (state: AccountState) => {
                state.register.loading = true;
            })
            .addCase(createAdminAccount.rejected, (state: AccountState, { payload }) => {
                state.register.loading = false;
                state.register.success = false;
                state.register.error = true;
                state.register.message = payload as string;
            })
            .addCase(createAdminAccount.fulfilled, (state: AccountState) => {
                state.register.loading = false;
                state.register.success = true;
                state.register.error = false;
            })
            .addCase(changePasswordAccount.pending, (state: AccountState) => {
                state.changeAccount.loading = true;
            })
            .addCase(changePasswordAccount.rejected, (state: AccountState, { payload }) => {
                state.changeAccount.loading = false;
                state.changeAccount.success = false;
                state.changeAccount.error = true;
                state.changeAccount.message = payload as string;
            })
            .addCase(changePasswordAccount.fulfilled, (state: AccountState) => {
                state.changeAccount.loading = false;
                state.changeAccount.success = true;
                state.changeAccount.error = false;
            })
            .addCase(sendEmailAccount.pending, (state: AccountState) => {
                state.sendEmailAccount.loading = true;
            })
            .addCase(sendEmailAccount.rejected, (state: AccountState, { payload }) => {
                state.sendEmailAccount.loading = false;
                state.sendEmailAccount.success = false;
                state.sendEmailAccount.error = true;
                state.sendEmailAccount.message = payload as string;
            })
            .addCase(sendEmailAccount.fulfilled, (state: AccountState) => {
                state.sendEmailAccount.loading = false;
                state.sendEmailAccount.success = true;
                state.sendEmailAccount.error = false;
            })
            .addCase(resetPasswordAccount.pending, (state: AccountState) => {
                state.resetAccount.loading = true;
            })
            .addCase(resetPasswordAccount.rejected, (state: AccountState, { payload }) => {
                state.resetAccount.loading = false;
                state.resetAccount.success = false;
                state.resetAccount.error = true;
                state.resetAccount.message = payload as string;
            })
            .addCase(resetPasswordAccount.fulfilled, (state: AccountState) => {
                state.resetAccount.loading = false;
                state.resetAccount.success = true;
                state.resetAccount.error = false;
            })
            .addCase(deleteAccount.pending, (state: AccountState) => {
                state.delete.loading = true;
            })
            .addCase(deleteAccount.rejected, (state: AccountState) => {
                state.delete.loading = false;
                state.delete.success = false;
                state.delete.error = true;
            })
            .addCase(deleteAccount.fulfilled, (state: AccountState) => {
                state.delete.loading = false;
                state.delete.success = true;
                state.delete.error = false;
            })
            .addCase(getDetailAccount.pending, (state: AccountState) => {
                state.detail.loading = true;
            })
            .addCase(getDetailAccount.rejected, (state: AccountState, { payload }) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
                state.detail.messages = payload as string;
            })
            .addCase(getDetailAccount.fulfilled, (state: AccountState) => {
                state.detail.loading = false;
                state.detail.success = true;
                state.detail.error = false;
            })
            .addCase(updateAdminAccount.pending, (state: AccountState) => {
                state.update.loading = true;
            })
            .addCase(updateAdminAccount.rejected, (state: AccountState, { payload }) => {
                state.update.loading = false;
                state.update.success = false;
                state.update.error = true;
                state.update.message = payload as string;
            })
            .addCase(updateAdminAccount.fulfilled, (state: AccountState) => {
                state.update.loading = false;
                state.update.success = true;
                state.update.error = false;
            });
    },
});

export const AccountSelector = (state: RootState) => state.account;
export const { setListAccount, resetRegisterState, setNewAccount, resetDeleteState, setDetailAccount, resetUpdateState } = accountSlice.actions;
