import {getAxiosClientWithJWTToken} from 'api';
import {RegisterChargerProtocolData, chargerProtocolData} from 'types'

export const createChargerProtocol= (params: RegisterChargerProtocolData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger/protocol/create`, params);
};

export const getListChargerProtocol = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger/protocol/list', { params });
};

export const UpdateChargerProtocol = (params: { uuid: string, name: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger/protocol/update`, params);
};