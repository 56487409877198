import {getAxiosClientWithJWTToken} from 'api';
import {RegisterChargerTypeData, chargerTypeData} from 'types'

export const createChargerType= (params: RegisterChargerTypeData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_types/create`, params);
};

export const getListChargerType = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger_types', { params });
};

export const UpdateChargerType = (params: { uuid: string, name: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_types/update`, params);
};
