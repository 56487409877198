import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Constant} from 'config/constant';
import {RootState} from 'redux/store';
import {getErrorMessage,} from 'api';
import {
    RegisterChargerProtocolData
} from 'types';
import {createChargerProtocol, getListChargerProtocol, UpdateChargerProtocol} from 'api/chargerProtocol';
import { Data } from '@react-google-maps/api';

export type chargerProtocolData = {
    uuid: string,
    name: string
}
export type ListChargerProtocol = {
    error: boolean;
    loading: boolean;
    success: boolean;
    chargerProtocol: chargerProtocolData[];
    status: string;
};

export type ChargerProtocolState = {
    status: number;
    chargerProtocolData: chargerProtocolData[];
    listChargerProtocol: ListChargerProtocol;
    detailsChargerProtocol: chargerProtocolData;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | RegisterChargerProtocolData;
    };
    update: {
        success: boolean;
        error: boolean;
        messages: string | RegisterChargerProtocolData;
        loading: boolean;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    detail: {
        success: boolean;
        error: boolean;
        messages: string | RegisterChargerProtocolData;
        loading: boolean;
        dataDetail: chargerProtocolData;
    };
}

export const createChargerProtocolA = createAsyncThunk(
    'admin/charger-protocol/store',
    async (params: RegisterChargerProtocolData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createChargerProtocol(params);
            const { data = {}, success } = response.data;
            if (success) {

                dispatch(setNewChargerProtocol(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setNewChargerProtocol(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateChargerProtocol = createAsyncThunk(
    '/admin/charger-protocol/update',
    async (params: { uuid: string, name: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await UpdateChargerProtocol(params);
            const { data = {}, success } = response.data;
            if (success) {
            dispatch(setUpdateChargerProtocol({params}));
                return true;
            }
        } catch (error: any) {
            dispatch(setUpdateChargerProtocol(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const chargerProtocolSlice = createSlice(
    {
        name: 'chargerProtocol',
        initialState: {
            status: Constant.DEFAULT_STATUS,
            chargerProtocolData: [],
            register: {
                loading: false,
                success: false,
                error: false,
                message: '',
            },
            update: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            delete: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            listChargerProtocol: {
                error: false,
                loading: false,
                success: false,
                notification: [],
                pagination: {
                    total: 0,
                    perPage: Constant.PAGE_SIZE_NEW,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                },
                status: '',
            },
            listSearchPlace: {
                error: false,
                loading: false,
                success: false,
                places: [],
            }
        } as unknown as ChargerProtocolState,
        reducers:{
            resetRegisterState: (state: ChargerProtocolState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.register = {
                    success: false,
                    loading: false,
                    message: '',
                    error: false,
                };
            },
            setNewChargerProtocol: (state, action) => {
                state.chargerProtocolData = action?.payload ?? [];
                state.status = action?.payload?.response?.status;
            },
            setListChargerProtocol: (state: ChargerProtocolState, { payload }) => {
                const { chargerProtocol, pagination } = payload;
                state.listChargerProtocol.chargerProtocol = chargerProtocol;
            },
            setDetailsChargerProtocol:(state: ChargerProtocolState, {payload}) => {
                const {detailsChargerProtocol} = payload;
                state.detailsChargerProtocol = {
                    name: payload.detailsChargerProtocol.name,
                    uuid: payload.detailsChargerProtocol.uuid
                }
            },
            setDeleteChargerProtocol: (state: ChargerProtocolState, { payload }) => {
                const { chargerProtocol } = payload;
                state.listChargerProtocol.chargerProtocol = chargerProtocol;
                state.listChargerProtocol.chargerProtocol = payload?.response?.status;
            },
            setUpdateChargerProtocol: (state, action) => {
                state.chargerProtocolData = action?.payload?.params ?? [];
                state.status = action?.payload?.response?.status;
            },
            resetUpdateState: (state: ChargerProtocolState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetDeleteState: (state: ChargerProtocolState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.delete = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetListChargerProtocolState: (state: ChargerProtocolState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(createChargerProtocolA.pending, (state: ChargerProtocolState) => {
                    state.register.loading = true;
                })
                .addCase(createChargerProtocolA.rejected, (state: ChargerProtocolState, { payload }) => {
                    state.register.loading = false;
                    state.register.success = false;
                    state.register.error = true;
                    state.register.message = payload as string | RegisterChargerProtocolData;
                })
                .addCase(createChargerProtocolA.fulfilled, (state: ChargerProtocolState) => {
                    state.register.loading = false;
                    state.register.success = true;
                    state.register.error = false;
                })
                .addCase(updateChargerProtocol.pending, (state: ChargerProtocolState) => {
                    state.update.loading = true;
                })
                .addCase(updateChargerProtocol. rejected, (state: ChargerProtocolState) => {
                    state.update.loading = false;
                    state.update.success = false;
                    state.update.error = true;
                })
                .addCase(updateChargerProtocol. fulfilled, (state: ChargerProtocolState) => {
                    state.update.loading = false;
                    state.update.success = true;
                    state.update.error = true;
                })
        },
    }
);

export const fetchListChargerProtocol = createAsyncThunk(
    'admin/charger-protocol',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListChargerProtocol(params);
            const {
                data = [],
                success,
            } = response.data;
            if (success) {
                let m = 0;
                if(data.length>0) {
                    for (const elem of data) {
                        m+=1;
                        elem['no'] = m;
                    }
                }
                dispatch(setListChargerProtocol({ chargerProtocol: data }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListChargerProtocol(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const {
    setListChargerProtocol,
    resetRegisterState,
    setNewChargerProtocol,
    setDeleteChargerProtocol,
    resetUpdateState,
    setUpdateChargerProtocol,
    resetDeleteState,
    resetListChargerProtocolState,
    setDetailsChargerProtocol
} = chargerProtocolSlice.actions
export const chargerProtocolSelector = (state: RootState) => state.chargerProtocol;