import { getAxiosClient, getAxiosClientWithJWTToken } from "api";
import {LoginRequest} from "redux/slice/loginSlice";

export const login = (params: LoginRequest) => {
  return getAxiosClient().post('facility/email/otp/verify', {"email" : params.username, "otp" : params.password});
};

export const getUserData = () => {
  return getAxiosClientWithJWTToken().get("auth-profile");
};
